<template>
  <div class="gongsi">
    <div class="top"><img src="../../common/image/banner4.png" alt="" /></div>
    <div class="main flex">
      <div class="left">
        <div
          v-for="(i, k) in moshi"
          :key="k"
          style="cursor: pointer"
          @click="goto(i,k)"
          :class="['wow  lightSpeedIn shenglue1', id == k ? 'hight' : '']"
        >
          {{ i.itemName }}
        </div>
      </div>
      <div class="right">
          <!-- 详情 -->
        <detail :list="moshi[id]" />
      </div>
    </div>
  </div>
</template>
<script>
import detail from "./gongsidetail";
export default {
  name: "gongsi",
  props: ["datalist"],
  data() {
    return {
      id: 0, //高亮下标
      moshi:[] // 公司列表
    };
  },
  components: {
    detail,
  },
  created() {
    this.api.aboutUs().then((res) => {
      this.moshi = res.data
    })
  },
  wacth: {},
  methods: {
    goto(e,k) {
      //高亮
      this.id = k;
    },
    
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.gongsi {
  .top {
    margin-bottom: 45px;
    img {
      width: 100%;
    }
  }
  .main {
    width: 1200px;
    margin: 0 auto;
    min-height: 500px;
    margin-bottom: 20px;
    .left {
      text-align: center;
      min-width: 150px;
      max-width: 150px;
      border-left: 1px solid #eeeeee;
      border-right: 1px solid #eeeeee;
      .hight {
        color: #cc2030;
        border-left: 3px solid #cccccc;
      }
      div {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
    }
    .right {
      margin-left: 40px;
    }
  }
}
</style>
