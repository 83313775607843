<template>
  <div class="detail" v-html="list&&list.content">
  </div>
</template>
<script>
export default {
  name: "detail",
  props: ["list"],
  data() {
    return {}
  },
  create() {},
  wacth: {},
  methods: {},
  computed: {},
}
</script>
<style lang="scss" scoped>

</style>
